<template>
  <div>
    <b-card>
      <h4>Game: WORD-PRONUNCIATION</h4>
      <form-generator :model="componentData.content" :schema="game_schema" />
    </b-card>
    <h4>Questions</h4>
    <dynamic-zone ref="questions" item-collapse="last"
      :items="componentData.content.questions"
      :computeTitleFn="questionTitle"
      :default-data="{words: []}"
      :enable-create-item="true"
    >
        <template v-slot="{item}">
            <dynamic-zone ref="word_list" item-collapse="last"
                :items="item.words"
                :title_field="'word'"
                :default-data="{ word: 'word', is_correct_answer: false, pronunciation_time: 0 }"
                :enable-create-item="false"
                :selectable_header="true"
                :selectable_header_field="'is_correct_answer'"
            >
                <template v-slot="word">
                  <div v-if="word.item.custom_word || !word.item._id">
                    <span>Word</span>
                    <b-input v-model="word.item.word"/>
                  </div>
                  <div v-if="word.item.is_correct_answer">
                    <span>Time pronunciation</span>
                    <b-input type="number" v-model="word.item.pronunciation_time" :min="1" />
                  </div>
				          <span v-else />
                </template>
            </dynamic-zone>
              <word-select-box :id="item.id" :milestone-id="componentData.content.milestone"
              @wordSelected="addWord"
              @wordAdded="addWord"
              @click.native="current_question = item"
              :allowCustomWord="true"
              :customWordDefault="{word:'', is_correct_answer: false, custom_word: true}"
            />
        </template>
    </dynamic-zone>

  </div>
</template>

<script>
import WordSelectBox from '../../../word/views/_components/WordSelectBox.vue'
const game_schema = [
    { cols: 12, fields: [
        { field: 'star_number', label: 'Star Number', input_type: 'number', validate: { required: true} },
        // { field: 'score', label: 'Score', input_type: 'number', validate: { required: true } },
    ]},
];
export default {
  components: {
    WordSelectBox,
  },
  props: {
    componentData: {type: Object, required: true},
  },
  data(){
    return {
        game_schema,
        current_question: null
    }
  },
  methods:{
    addWord(word) {
      if (!word._id){
        this.$refs.word_list.addItem({word: word.word, custom_word: true})
      }
      else {
        let existed_word = this.componentData.content.questions[this.current_question.index].words.find(item => item._id === word._id);
        if(existed_word) {
          this.$store.dispatch('pushErrorNotify', { text: 'existed word!' });
          return;
        }
        this.$refs.word_list.addItem({ _id: word._id, word: word.title, audio: word.audio })
      }
    },
    questionTitle(item){
        return `Question ${item.index + 1}`
    }
  }
};
</script>
